import { faSave, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Input, message, PageHeader, Popconfirm } from "antd";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { sessionService } from "redux-react-session";
import Globalset from "../Globalset";

function UserView(){

    const session = useSelector((state) => state.session);
    const ref = useRef();
    const [lod, setLod] = useState(false);
    
    const logout = () => {
        sessionService.deleteUser();
    }

    const finish = e => {
        setLod(true);
        e['id'] = session.user.id;
        axios.post(Globalset.app_url+'/user/update', serialize(e), Globalset.app_authentic).then(res=>{
            setLod(false);
            if(res.status===200){
                message.success('Update data tersimpan !');
            } else {
                message.error('Terjadi kesalahan !');
            }
        });
    }

    useEffect(()=>{
        axios(Globalset.app_url+'/user/info?id='+session.user.id, Globalset.app_authentic).then(res=>{
            let json = res.data;
            ref.current.setFieldsValue({
                nama: json['nama'],
                jabatan: json['jabatan'],
                email: json['email'],
                telp: json['telp'],
            });
        });
    }, []);

    return(
        <>
        <PageHeader title='Informasi User' extra={<Popconfirm title='Yakin ingin logout ?' onConfirm={logout}><Button><>Logout <FontAwesomeIcon icon={faSignOutAlt} /></></Button></Popconfirm>} />
        <Form ref={ref} onFinish={finish} layout='vertical'>
            <Form.Item label='Nama' name='nama'>
                <Input />
            </Form.Item>
            <Form.Item label='Jabatan' name='jabatan'>
                <Input />
            </Form.Item>
            <Form.Item label='Email' name='email'>
                <Input type='email' />
            </Form.Item>
            <Form.Item label='HP' name='telp'>
                <Input type='tel' />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="primary"><><FontAwesomeIcon icon={faSave} /> Simpan</></Button>
            </Form.Item>
        </Form>
        </>
    );
}

export default UserView;