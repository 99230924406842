import ClassicEditor  from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { faAdd, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AutoComplete, Button, Col, DatePicker, Divider, Form, Input, message, Modal, PageHeader, Popconfirm, Row, Select, Space, Table, TimePicker } from "antd";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Globalset from "../Globalset";

const { Option } = Select;

function AgendaAddView(){

    const [lod, setLod] = useState(false);
    const nav = useNavigate();
    const [tipe, setTipe] = useState([]);
    const tipe_list = tipe.map(e=>(
        <Option key={e['id']}>{e['nama']}</Option>
    ));

    const [topik, setTopik] = useState([]);
    const topik_kolom = [
        { title: 'Urut', render: (e,i,s)=>( s+1 )},
        { title: 'Topik', render: (e,i,s)=>( <Input placeholder="Ketik disini" value={e['nama']} onChange={f=>{ topik[s]['nama'] = f.target.value; setTopik([...topik]) }} /> )  },
        
    ]
    const modhadir_show = e => {
        setKehadiranindex(e);
        if(e!==null){
            modhadirref.current.setFieldsValue({'nama':kehadiran[e]['nama']});
        } 
        setModhadir(true);
    }
    const [modhadir, setModhadir] = useState(false);
    const modhadirfinish = e => {
        if(kehadiranindex===null){
            kehadiran.push(e);
        } else {
            kehadiran[kehadiranindex] = e;
        }
        
        setKehadiran([...kehadiran]);
        setModhadir(false);
        modhadirref.current.setFieldsValue({
            nama : ''
        });
    }
    const modhadirref = useRef();
    const [kehadiranindex, setKehadiranindex] = useState(null);
    const [kehadiran, setKehadiran] = useState([]);
    const kehadiran_kolom = [
        {title: 'Urut', render:(e,i,s)=>(s+1)},
        {title: 'Nama', dataIndex: 'nama'},
        {title: 'Opsi', render: (e,i,s) =>(
            <Space>
                <Button size="small" onClick={()=>{ modhadir_show(s) }}>Ubah</Button>
                <Popconfirm title="Konfirmasi hapus?" onConfirm={()=>{
                    kehadiran.splice(s, 1);
                    setKehadiran([...kehadiran]);
                }}><Button type="dashed" size="small">Hapus</Button></Popconfirm>
            </Space>
        )}
    ]

    const [pencatat, setPencatat] = useState([]);
    const pencatat_cari = e => {
        axios(Globalset.app_url+'/agenda/registered?keywords='+e, Globalset.app_authentic).then(res2=>{
            let json = res2.data;
            let tmp = [];
            for(let a=0; a<json.length; a++){
                tmp.push({value: json[a]['pencatat']});
            }
            setPencatat(tmp);
        });
    }

    const topik_add = () => {
        topik.push({nama: ''});
        setTopik([...topik]);
    }

    const finish = e => {
       
        e['tanggal'] = e['tanggal'].format('YYYY-MM-DD');
        e['waktu_dari'] = e['waktu_dari'].format('HH:mm:ss');
        e['waktu_sampai'] = e['waktu_sampai'].format('HH:mm:ss');
        e['kehadiran'] = JSON.stringify(kehadiran);
        e['topik'] = JSON.stringify(topik);

        setLod(true);
        axios.post(Globalset.app_url+'/agenda/add', serialize(e), Globalset.app_authentic).then(e=>{
            //console.log(e);
            if(e.status===200){
                let newid = e.data;
                message.success('Pembuatan Agenda berhasil !');
                setTimeout(()=>{
                    nav('/agenda/info/'+newid);
                }, 1000);
            } else {
                message.error(Globalset.app_error);
            }
        }).catch(()=>{
            setLod(false);
            message.error(Globalset.app_error);
        });
    }

    useEffect(()=>{
        axios(Globalset.app_url+'/agenda/type', Globalset.app_authentic).then(res=>{
            setTipe(res.data);
        });
    }, []);

    return(
        <>
        <PageHeader title='Buat Notulen Rapat' onBack={()=>{ nav(-1); }} />
        <Form layout="vertical" onFinish={finish}>
            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item label='Tanggal' name='tanggal' rules={[{required: true}]}>
                        <DatePicker className="w-100" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label='Waktu Dari' name='waktu_dari' rules={[{required: true}]}>
                        <TimePicker className="w-100" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label='Waktu Sampai' name='waktu_sampai' rules={[{required: true}]}>
                        <TimePicker className="w-100" />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item label='Lokasi' name='lokasi' rules={[{required: true}]}>
                <Input />
            </Form.Item>
            <div className="row">
                <div className="col-md-6 col-12">
                    <Form.Item label='Jenis Meeting' name='idtipe'>
                        <Select>
                            {tipe_list}
                        </Select>
                    </Form.Item>
                </div>
                <div className="col-md-6 col-12">
                    <Form.Item label='Pencatat' name='pencatat'>
                        <AutoComplete options={pencatat} onChange={pencatat_cari} />
                    </Form.Item>
                </div>
            </div>

            <Form.Item label='Dihadiri oleh :'>
                <Table size="small" pagination={false} dataSource={kehadiran} columns={kehadiran_kolom} footer={()=>( <Button size="small" onClick={()=>{ modhadir_show(null) }}><><FontAwesomeIcon icon={faAdd} /> Tambahkan</></Button> )} />
            </Form.Item>
            <Form.Item label='Topik Pembahasan :'>
                <Table size="small" pagination={false} dataSource={topik} columns={topik_kolom} footer={()=>( <Button size="small" onClick={topik_add}><><FontAwesomeIcon icon={faAdd} /> Tambahkan</></Button> )} />
            </Form.Item>
            <Form.Item>
                <Button loading={lod} type="primary" htmlType="submit"><><FontAwesomeIcon icon={faSave} /> Simpan</></Button>
            </Form.Item>
        </Form>
        <Modal visible={modhadir} title='Yang Hadir' onCancel={()=>{ setModhadir(false) }} onOk={()=>{ modhadirref.current.submit(); }} >
            <Form ref={modhadirref} layout="vertical" onFinish={modhadirfinish} >
                <Form.Item label='Nama' name='nama'>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
        </>
    );
}

export default AgendaAddView;