
import Globalset from '../Globalset';
import { Button, Card, Form, Input, Layout, message } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { serialize } from 'object-to-formdata';
import { sessionService } from 'redux-react-session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faKey, faSignIn } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

const { Content } = Layout;

function UserLoginView(){

    const [lod, setLod] = useState(false);
    const finish = e => {
        setLod(true);
        axios.post(Globalset.app_url+'/user/login', serialize(e), Globalset.app_authentic).then(res=>{
            setLod(false);
            let json = res.data;
            if(json['status']===1){
                message.success(json['message']);
                let data = JSON.parse(json['user']);
                data['app_name'] = Globalset.app_name;
                data['lastlogin'] = moment().format('YYYY-MM-DD HH:mm:ss');
                sessionService.saveUser(data).then(res=>{
                    console.log('simpan');
                });
            } else {
                message.error(json['message']);
            }
        });
    }

    return(
        <Layout style={{height: '100vh', overflow: 'hidden'}}>
            <Content>
                <br /><br /><br /><br />
                <div className='row'>
                    <div className='col-md-4'></div>
                    <div className='col-md-4'>
                        <Card title={'Login - '+Globalset.app_name} className='shadow-sm'>
                            <Form onFinish={finish}>
                                <Form.Item name='email' rules={[{required: true, message: 'Email tidak boleh kosong !'}]} >
                                    <Input addonBefore={<FontAwesomeIcon icon={faEnvelope} />} placeholder='Email' />
                                </Form.Item>
                                <Form.Item name='password' rules={[{required: true, message: 'Password tidak boleh kosong !'}]}>
                                    <Input.Password addonBefore={<FontAwesomeIcon icon={faKey} />} placeholder='Password' />
                                </Form.Item>
                                <Form.Item>
                                    <Button loading={lod} htmlType='submit' type='primary'><><FontAwesomeIcon icon={faSignIn} /> Login</></Button>
                                </Form.Item>
                            </Form>
                        </Card>
                        <br />
                        <Button className='float-end' type='dashed'><a href={Globalset.app_disposisi}><FontAwesomeIcon icon={faEnvelope} /> Disposisi Surat klik disini</a></Button>
                    </div>
                    <div className='col-md-4'></div>
                </div>
          
            </Content>
        </Layout>
    );
}

export default UserLoginView;