import { Button, Card, DatePicker, Descriptions, Divider, Form, Input, message, Modal, PageHeader, Popconfirm, Space, Tooltip, Typography } from "antd";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Globalset from "../Globalset";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faPlus, faPrint, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { serialize } from "object-to-formdata";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const { Title } = Typography;

function AgendaInfoView(){

    const nav = useNavigate();
    const [lod, setLod] = useState(false);
    const param = useParams();
    const [info, setInfo] = useState({});
    const [kehadiran, setKehadiran] = useState([]);
    const kehadiran_list = kehadiran.map(e=>(<li key={e['id']}>{e['nama']}</li>));
    const [topik, setTopik] = useState([]);
    const topik_listumum = topik.map(e=>(
        <li key={e['id']}>{e['topik']}</li>
    ));
    const topik_list = topik.map((e,i)=>{

        let userlist = e['responsible'].map((s,g)=>(
            <li className="pb-1"><Input placeholder="Ketik responsible user" size="small" value={s['nama']} onChange={ f=>{ topik[i]['responsible'][g]['nama'] = f.target.value; setTopik([...topik]) } } /></li>
        ));

        return (
        <>
        <Divider dashed/>
        <Card key={e['id']} title={ <Tooltip title='Klik untuk Ubah Topik'><Input style={{fontWeight: 'bold'}} size="large" bordered={false} value={e['topik']} onChange={e=>{ topik[i]['topik'] = e.target.value; setTopik([...topik]); }} /></Tooltip>} extra={<Popconfirm title='Yakin hapus Topik ini?' onConfirm={()=>{ hapus(e['id'], i) }} ><Button size="small" type="dashed"><><FontAwesomeIcon icon={faTrash} /> Hapus</></Button></Popconfirm>} >
            <Form layout="vertical">
                <Form.Item label='Discussion'>
                    <CKEditor editor={ClassicEditor} data={e['diskusi']} onChange={(f,s)=>{ topik[i]['diskusi'] = s.getData(); setTopik([...topik]); }} />
                </Form.Item>
                <Form.Item label='CONCLUSION'>
                    <CKEditor editor={ClassicEditor} data={e['kesimpulan']} onChange={(f,s)=>{ topik[i]['kesimpulan'] = s.getData(); setTopik([...topik]); }} />
                </Form.Item>
                <Form.Item label='ACTION ITEMS'>
                    <CKEditor editor={ClassicEditor} data={e['tindakan']} onChange={(f,s)=>{ topik[i]['tindakan'] = s.getData(); setTopik([...topik]); }} />
                </Form.Item>
                <div className="row">
                    <div className="col-md-6 col-12">
                        <Form.Item label='PERSON RESPONSIBLE'>
                            <ol>{userlist}</ol>
                            <Button size="small" onClick={()=>{ topik[i]['responsible'].push({id: '0', idrapat_topik: e['id'], nama: ''}); setTopik([...topik]) }}><><FontAwesomeIcon icon={faPlus} /> Add Person</></Button>
                        </Form.Item>
                    </div>
                    <div className="col-md-6 col-12">
                        <Form.Item label='DEADLINE'>
                            {e['deadline']!=='0000-00-00' ?
                            <DatePicker value={moment(e['deadline'], 'YYYY-MM-DD')} onChange={e=>{ topik[i]['deadline'] = e.format('YYYY-MM-DD'); setTopik([...topik]);  }} />
                            : <DatePicker value={undefined} onChange={e=>{ topik[i]['deadline'] = e.format('YYYY-MM-DD'); setTopik([...topik]);  }} /> }
                        </Form.Item>
                    </div>
                </div>
            </Form>
            <p><i><small>*Untuk menghapus Person Responsible kosongkan nama</small></i></p>
        </Card>
        </>)
    });

    const [mod, setMod] = useState(false);
    const modref = useRef();

    const hapus = (id, index) => {
        axios.post(Globalset.app_url+'/agenda/topic_del', serialize({id: id}), Globalset.app_authentic).then(res=>{
            if(res.status===200){
                topik.splice(index, 1);
                setTopik([...topik]);
            } else {
                message.error(Globalset.app_error); 
            }
        }).catch(()=>{ message.error(Globalset.app_error); });
    }

    const finish = () => {

        //console.log(topik);

        setLod(true);
        axios.post(Globalset.app_url+'/agenda/topic_update', serialize({data: JSON.stringify(topik)}), Globalset.app_authentic).then(res=>{
            setLod(false);
            //console.log(res.data);
            if(res.status===200){
                message.success('Update berhasil tersimpan !');
                filltopic();
            }
        }).catch(()=>{ message.error(Globalset.app_error) });
    }

    const filltopic = () => {
        axios(Globalset.app_url+'/agenda/topic?id='+param.id, Globalset.app_authentic).then(res2=>{
            if(res2.data!=='access denied !'){
                console.log(res2.data);
                setTopik(res2.data);
            }
        }).catch(()=>{ message.error(Globalset.app_error) });
    }

    const modfinish = e => {
        setMod(false);
        e['idrapat'] = param.id;
        axios.post(Globalset.app_url+'/agenda/topic_add', serialize(e), Globalset.app_authentic).then(res=>{
            if(res.status===200){
                filltopic();
            }
        });
    }

    useEffect(()=>{
        axios(Globalset.app_url+'/agenda/info?id='+param.id, Globalset.app_authentic).then(res=>{
            //console.log(res.data);
            setInfo(res.data);
            axios(Globalset.app_url+'/agenda/attendees?id='+param.id, Globalset.app_authentic).then(res3=>{
                //console.log(res3);
                if(res3.data!=='access denied !'){
                    setKehadiran(res3.data);
                    filltopic();
                }
            }).catch(()=>{ message.error(Globalset.app_error) });
        }).catch(()=>{ message.error(Globalset.app_error) });
    }, []);

    return(
        <>
        <PageHeader title='Minutes Meeting' onBack={()=>{ nav(-1)}} extra={<Button><a href={Globalset.app_url+'/agenda/pdf/'+param.id} target='_blank'><FontAwesomeIcon icon={faPrint} /> Cetak</a></Button>} />
        <Space direction="vertical" className="w-100">
            <Card>
                <Descriptions>
                    <Descriptions.Item label='Time & Venue'>{info['tanggal']} / {info['waktu_dari']} - {info['waktu_sampai']}</Descriptions.Item>
                    <Descriptions.Item label='Lokasi'>{info['lokasi']}</Descriptions.Item>
                </Descriptions>
            </Card>
            <table className="table">
                <tbody>
                    <tr><th>TYPE OF MEETING</th><td>: {info['tipe']}</td></tr>
                    <tr><th>NOTE TAKER</th><td>: {info['pencatat']}</td></tr>
                    <tr><th>ATTENDEES</th><td>: <ol>{kehadiran_list}</ol></td></tr>
                </tbody>
            </table>
            <Title level={4}>Topics :</Title>
            <ol>
                {topik_listumum}
            </ol>
            <Button size="small" onClick={()=>{ setMod(true) }}><><FontAwesomeIcon icon={faAdd} /> Add New Topic</></Button>
            {topik_list}
            <br />
            <Button type="primary" loading={lod} onClick={finish}><><FontAwesomeIcon icon={faSave} /> Simpan</></Button>
            <br />
            
        </Space>
        <Modal visible={mod} title='Buat Topik Baru' onOk={()=>{ modref.current.submit() }} onCancel={()=>{ setMod(false) }}>
            <Form onFinish={modfinish} layout='vertical' ref={modref}>
                <Form.Item label='Isi Topik' name='topik' rules={[{required: true}]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
        </>
    );
}

export default AgendaInfoView;