import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Route, Routes } from "react-router-dom";
import { sessionService } from "redux-react-session";
import Globalset from "./Globalset";
import AgendaView from "./views/AgendaView";
import AgendaAddView from "./views/AgendaAddView";
import AgendaInfoView from "./views/AgendaInfoView";
import UserView from "./views/UserView";
import UserLoginView from "./views/UserLoginView";
import { Button, Layout, Menu, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { faCalendar, faUser } from "@fortawesome/free-solid-svg-icons";
import { setLokasi } from "./lokasiSlice";

const { Header, Content } = Layout;

function App() {

    const user = useSelector((state) => state.session.user);
    const loaduser = () => {
        sessionService.loadUser().then(e=>{
            if(e.app_name===Globalset.app_name){
                sessionService.saveUser(e).then(()=>{
                    if(e.lastlogin===undefined){
                        sessionService.deleteUser();
                    } else {
                        let lsg = moment(e.lastlogin, 'YYYY-MM-DD HH:mm:ss');
                        let now = moment();
                        let totaljam = now.diff(lsg, 'hours');
                        if(totaljam>8){
                            message.warn('Sesi habis !');
                            sessionService.deleteUser();
                        }
                    }
                });
            } 
            
        }).catch(()=>{
            console.log('gak ada');
        });
    }

    const tanggal = moment().format('DD MMMM YYYY');
    const lokasi = useSelector((state) => state.lokasi.value);
    const dispatch = useDispatch();

    useEffect(()=>{
        loaduser();
    }, []);

    return (
        user.id===undefined ?
        <Routes>
            <Route path="*" element={<UserLoginView />} />
        </Routes>
        :
        <Layout style={{height: '100vh'}}>
            <Header style={{backgroundColor: 'white', zIndex: 1}} className='d-flex flex-row shadow-sm'>
                <div className="">
                    <Button type="link"><b>{Globalset.app_name}</b></Button>
                </div>
                <div className="d-flex flex-fill justify-content-center">
                    {tanggal}
                </div>
                <div>
                    <Menu mode="horizontal" selectedKeys={[lokasi]} >
                        <Menu.Item icon={<FontAwesomeIcon icon={faCalendar} />} key='agenda' onClick={()=>{ dispatch(setLokasi('agenda')); }}><Link to='/agenda'>Agenda</Link></Menu.Item>
                        <Menu.Item icon={<FontAwesomeIcon icon={faUser} />} key='user' onClick={()=>{ dispatch(setLokasi('user')); }}><Link to='/user'>User</Link></Menu.Item>
                    </Menu>
                </div>
            </Header>
            <Content className="p-2" style={{overflow: 'auto', zIndex: 0, backgroundColor: 'white'}}>
                <div className="container">
                    <Routes>
                        <Route path="/user" element={<UserView />} />

                        <Route path="/" element={<AgendaView />} />
                        <Route path="/agenda" element={<AgendaView />} />
                        <Route path="/agenda/add" element={<AgendaAddView />} />
                        <Route path="/agenda/info/:id" element={<AgendaInfoView />} />
                    </Routes>
                </div>
                <br /><br /><br />
            </Content>
        </Layout>       
    );
}

export default App;
