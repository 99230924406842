import { faAdd, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, message, PageHeader, Popconfirm, Space, Table } from "antd";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Globalset from "../Globalset";

function AgendaView(){

    const [keywords, setKeywords] = useState('');
    const [data, setData] = useState([]);
    const colum = [
        {title: 'Tanggal', dataIndex: 'tanggal'},
        {title: 'Pencatat', dataIndex: 'pencatat'},
        {title: 'Lokasi', dataIndex: 'lokasi'},
        {title: 'Opsi', render: (e,i,s)=>(
            <Space>
                <Button><Link to={'/agenda/info/'+e['id']}><FontAwesomeIcon icon={faEye} /> Lihat</Link></Button>
                <Popconfirm title='Yakin hapus Agenda ini ?' onConfirm={()=>{ hapus(e['id'], s) }}><Button><FontAwesomeIcon icon={faTrash} /></Button></Popconfirm>
            </Space>
        )}
    ]

    const find = () => {
        axios(Globalset.app_url+'/agenda?keywords='+keywords, Globalset.app_authentic).then(res=>{
            if(res.data!=='access denied !'){
                setData(res.data);
            }
        });
    }

    const hapus = (id, index) => {
        axios.post(Globalset.app_url+'/agenda/del', serialize({id: id}), Globalset.app_authentic).then(res=>{
            message.success('Hapus berhasil !');
            data.splice(index, 1);
            setData([...data]);
        }).catch(()=>{ message.error(Globalset.app_error) });
    }

    useEffect(find, []);
    useEffect(find, [keywords]);

    return (
        <>
        <PageHeader title='Agenda' extra={<Button type="primary"><Link to='/agenda/add'><FontAwesomeIcon icon={faAdd} /> Buat Baru</Link></Button>} />
        <Space direction="vertical" className="w-100">
            <Input.Search placeholder="Ketik pencarian dari Lokasi ..." allowClear value={keywords} onChange={e=>{ setKeywords(e.target.value) }} />
            <Table size="small" dataSource={data} columns={colum} />
        </Space>
        </>
    );
}

export default AgendaView;