
class Globalset {

    static app_name = 'Notulen Rapat'
    //static app_url = 'http://localhost/notulen_api/public';
    //static app_disposisi = 'https://localhost/disposisi';

    static app_url = 'https://notulen.pphakli.org/api/public';
    static app_disposisi = 'https://disposisi.pphakli.org';
    
    static app_error = 'Koneksi internet eror !';

    static app_auth_user = 'afandi'
    static app_auth_pass = '@hm4d@FanDi!'
    static app_authentic = {auth: {username: this.app_auth_user, password: this.app_auth_pass}}
    
}

export default Globalset;