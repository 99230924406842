import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    value : 'agenda'
}

export const lokasiSlice = createSlice({
    name: 'lokasi',
    initialState,
    reducers: {
        setLokasi: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const { setLokasi } = lokasiSlice.actions;
export default lokasiSlice.reducer;